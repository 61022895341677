import * as React from 'react';
import PersonalBlogWrapper from './style';
import Posts from './posts';
import Banner from './banner';
import SimpleSwiper from './post-slider';

type PersonalBlogProps = {};

const PersonalBlog: React.FunctionComponent<PersonalBlogProps> = (props) => {
  return (
    <PersonalBlogWrapper {...props}>
      {/* <Banner /> */}
      <SimpleSwiper />
      <Posts />
    </PersonalBlogWrapper>
  );
};

export default PersonalBlog;
